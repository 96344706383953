<template>
<b-form @submit.prevent>
    <b-row>
      <b-col cols="12">
        <b-form-group>
          <b-form-input
            id="subject"
            placeholder="Tema"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-textarea
          id="message"
          placeholder="Mensaje..."
          rows="10"
        ></b-form-textarea>
      </b-col>
     
    </b-row>
    <b-row class="mt-2">     
      <!-- submit and reset -->
      <b-col offset-md="4">
        <b-button
          type="submit"
          variant="primary"
          class="mr-1"
        >
          Enviar
        </b-button>
        <b-button
          type="reset"
          variant="outline-secondary"
        >
          Borrar
        </b-button>
      </b-col>
    </b-row>

  </b-form>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BForm, BButton,
} from 'bootstrap-vue'


export default ({
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
  },
  data() {
    return {

    }
  },
})
</script>
